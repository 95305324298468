<template>
    <div>
        <p class="text-xs font-semibold">Please save all changes before testing.</p>
        <div class="flex-grow p-3 flex-row flex">
            <label class="w-48 mb-0 leading-6">Preview Client:</label>
            <select class="transparent flex-grow" @change="updateClient($event)">
                <option v-for="s_client in clients" :value="s_client.id" :selected="active_client_id === s_client.id">{{ s_client.client_name }}</option>
            </select>
        </div>
        <label for="user" class="block mt-3">Select User for sample data:</label>
        <select v-if="client" id="user" v-model="user_sample_data">
            <option :value="user.id" v-for="user in client.user_sample">{{ user.name }}</option>
        </select>
        <label>Send test to email address:</label>
        <input id="email" name="email" type="text" v-model="email">
        <button v-if="email && client && user_sample_data" class="btn btn-primary w-full block mt-3 mb-3" v-on:click.prevent="testTrigger">Test Trigger</button>
    </div>
</template>

<script>

export default {
    name: "TriggerTest",
    props: {
        users_email:null,
        trigger:{
            required:true
        }
    },
    data: function () {
        return {
            clients:null,
            client:null,
            email:this.users_email ?? "",
            active_client_id: null,
            user_sample_data: null
        }
    },
    mounted() {
        this.getClients();
    },
    methods: {
        testTrigger: function(){

            let template_id = document.getElementById("email_template_id").value;

            if(!template_id) {
                this.$toast.error("Test Failed, No Template Set!");
                return false;
            }

            axios.post('/comms/emails/test/' + this.active_client_id,{
                acq: false,
                customer_id: this.user_sample_data,
                template_id: template_id,
                test_recipient: this.email
            })
                .then(response => {
                    this.$toast.success("Test Sent!");
                })
        },
        getClients: function() {
            axios.get('/comms/emails/setup/clients')
                .then(response => {
                    this.clients = response.data;
                    if(!this.client) {
                        this.getClientBranding(this.clients[0].id);
                    }
                })
                .catch(err => { console.log(err) });
        },
        updateClient: function(event) {
            if(event.target.value !== this.active_client_id) {
                this.getClientBranding(event.target.value);
            }
        },
        getClientBranding: function(client) {
            axios.get('/comms/emails/setup/' + client)
                .then(response => {
                    this.client = response.data;
                    this.active_client_id = client;
                })
                .catch(err => { console.log(err) });
        }
    }
}
</script>

<style scoped>

</style>
