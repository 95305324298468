<template>
    <div>
        <ul class="flex border-b mb-10">
            <li v-for='(tab, index) in tabs' :key='tab.title' @click='selectTab(index)' class="mx-5" :class="{ 'ml-0' : 0===index }">
                <a class="pb-3 pt-2 inline-block text-sm cursor-pointer" :class="{ 'font-semibold text-secondary border-b-4 border-secondary border-solid' : selectedIndex===index, 'text-gray-500 border-b-0' : selectedIndex!==index }">{{ tab.title }}</a>
            </li>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Tabs",
    data () {
        return {
            selectedIndex: 0,
            tabs: [],
        }
    },
    created () {
        this.tabs = this.$children
    },
    mounted () {
        this.selectTab(0)
    },
    methods: {
        selectTab (i) {
            this.selectedIndex = i

            // loop over all the tabs
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i)
            })
        }
    }
}
</script>

<style scoped>

</style>
