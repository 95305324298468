<template>
    <ul class="flex my-8 justify-end list-none p-0">
        <li v-if="pagination.current_page > 1">
            <a class="block bg-transparent border-gray-400 border-b border-t border-l py-1 px-3 text-xs ml-3 border rounded"
               href="#"
               @click.prevent="change(pagination.current_page -1)"
            >
                <span aria-hidden="true" class="page-link">‹</span>
            </a>
        </li>
        <li v-if="pagination.current_page === 1">
            <a class="block bg-transparent border-gray-400 text-gray-500 hover:border-gray-400 py-1 px-3 text-xs ml-3 border rounded disabled">
                <span aria-hidden="true" class="page-link">‹</span>
            </a>
        </li>
        <li>
            <a class="block bg-transparent border-gray-400 text-gray-600 border-b border-t border-l py-1 px-3 text-xs ml-3 border rounded disabled">
                {{ pagination.current_page }} / {{ pagination.last_page }}
            </a>
        </li>
        <li v-if="pagination.current_page < pagination.last_page">
            <a class="block bg-transparent border-gray-400 border-b border-t border-l py-1 px-3 text-xs ml-3 border rounded"
               href="#"
               @click.prevent="change(pagination.current_page + 1)"
            >
                <span aria-hidden="true" class="page-link">›</span>
            </a>
        </li>
        <li v-if="pagination.current_page === pagination.last_page">
            <a class="block bg-transparent border-gray-400 text-gray-500 hover:border-gray-400 py-1 px-3 text-xs ml-3 border rounded disabled">
                <span aria-hidden="true" class="page-link">›</span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            required: true
        },
        offset: {
            type: Number,
            default: 4
        }
    },
    computed: {
        pages() {
            if (!this.pagination.to) {
                return null;
            }
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            let to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            let pages = [];
            for (let page = from; page <= to; page++) {
                pages.push(page);
            }
            return pages;
        },
    },
    methods: {
        change: function(page) {
            this.pagination.current_page = page;
            this.$emit('paginate');
        }
    }
}
</script>
