<template>
    <div class="flex flex-col">
        <span class="hidden"><strong>Triggers:</strong> {{ triggers_status }}</span>
        <button class="btn btn-primary" v-bind:class="{ 'bg-red-600': current_status }" @click.prevent="setStatus">{{ triggers_action }}</button>
    </div>
</template>

<script>
export default {
    name: "DisableCompanyTriggers",
    props: {
        disabled: {
            required: true,
        },
        company: {
            required: true,
        },
    },
    data: function() {
        return {
            current_status:  !this.disabled,
            triggers_status: 'Enabled',
            triggers_action: 'Disable Triggers'
        }
    },
    mounted() {
        this.updateStatusAtts();
    },
    methods: {
        setStatus: function() {
            axios.post('/comms/triggers/company-status',{
                company: this.company,
                set_disabled_status: this.current_status
            }).then(response => {
                this.current_status = !response.data.status;
            })
        },
        updateStatusAtts: function () {
            this.triggers_status = this.current_status ? 'Enabled' : 'Disabled';
            this.triggers_action = this.current_status ? 'Disable Triggers' : 'Enable Triggers'
        }
    },
    watch: {
        current_status: function(){
            this.updateStatusAtts();
        }
    }
}
</script>

<style scoped>

</style>
