<template>
    <VueTailwindPicker class="mt-3" :selected-date="date" :start-date="today" :start-from-monday="true"
                       @change="(v) => date = v"
    >
        <input type="text" :name="name" v-model="date" />
    </VueTailwindPicker>
</template>

<script>

import VueTailwindPicker from 'vue-tailwind-picker';

export default {
    name: "DatePicker",
    components: {VueTailwindPicker},
    props: {
        inputDate: {
            default: null
        },
        name: {
            default: "date"
        },
    },
    data: function(){
        return {
            today: dayjs().format('YYYY-MM-DD') > this.inputDate ? this.inputDate : dayjs().format('YYYY-MM-DD'),
            date: this.inputDate ? this.inputDate : dayjs().format('YYYY-MM-DD'),
        }
    }
}
</script>

<style scoped>

</style>
