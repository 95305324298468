<template>
    <div class="bg-white overflow-hidden">
        <div class="px-4 py-5 border-b border-gray-200 sm:px-6 h-20 items-center flex">
            <p class="mb-0 max-w-2xl text-sm leading-5 text-gray-500">
                <strong>12 Month Comms Calendar</strong>
            </p>
        </div>
        <div>
            <ul class="bg-gray-50 divide-y divide-gray-200 py-1 list-unstyled">
                <li class="bg-gray-50 px-2 py-1 sm:grid sm:grid-cols-3 sm:px-6">
                    <div class="text-xs leading-5 text-secondary font-weight-bold">Date</div>
                    <div class="text-xs leading-5 text-secondary font-weight-bold">Category</div>
                    <div class="text-xs leading-5 text-secondary font-weight-bold">Subject</div>
                </li>
                <li v-for="date in dates" class="bg-gray-50 px-2 py-1 sm:grid sm:grid-cols-3 sm:px-6">
                    <div class="text-xs leading-5 font-medium text-secondary">
                        {{ date.date }}
                    </div>
                    <div class="text-xs leading-5 font-medium text-secondary capitalize">
                        {{ date.category }}
                    </div>
                    <div class="text-xs leading-5 font-medium text-gray-900 sm:mt-0">
                        {{ date.trigger_title }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompanyCommsCalendar",
    props: ['dates'],
    data: function() {
        return {
        }
    },
    computed: {
    },
    methods: {}
}
</script>
