<template>
    <div id="realtime-trigger-conditions">
        <label for="field" class="inline-block w-full mt-3">On Company update, evaluate the value of</label>
        <select id="field" name="field" v-model="field">
            <option value="null">Select field...</option>
            <option v-for="field in options.fields" :value="field">{{ field }}</option>
        </select>

        <label for="condition" class="inline-block w-full mt-3">(If value has changed) Trigger condition</label>
        <select id="condition" name="condition" v-model="condition">
            <option v-for="(condition,key) in options.conditions" :value="key">{{ condition.text }}</option>
        </select>

        <div v-if="['match','greater','lesser','between'].includes(condition)">
            <label for="match" class="inline-block w-full mt-3">Value/Range to compare (Define range with '-' separator)</label>
            <input type="text" v-model="match" id="match" name="match">
        </div>

    </div>
</template>

<script>
export default {
    name: "TriggerRealtime",
    props: {
        trigger: {
            default: function () {
                return {
                    triggerable: null
                }
            },
        },
        options: {
            default: function () {
                return []
            },
        }
    },
    data: function () {
        return {
            field: null,
            condition: "change",
            match: null
        }
    },
    mounted() {
        if(this.trigger) {
            this.field = this.trigger.triggerable.field;
            this.condition = this.trigger.triggerable.condition ?? "change";
            this.match = this.trigger.triggerable.match;
        }
    }
}
</script>

<style scoped>

</style>
