<template>
    <vmodal name="email-editor-modal" classes="w-full w-full inset-0" width="100%" height="100%" :reset="true">
        <email-editor-form :key="editor" @cancel="hideEditor(false)" @saveAndClose="saveClose" class="w-full h-full" v-bind:email_id="editing" v-bind:user_email="user_email"></email-editor-form>
    </vmodal>
</template>

<script>
import EmailEditorForm from "./EmailEditorForm";
export default {
    name: "EmailEditorModal",
    components: {EmailEditorForm},
    props: {
        refresh: {
            default: false
        },
        user_email: {
            default: ""
        }
    },
    data() {
        return {
            editing:null,
            editor:1
        }
    },
    methods: {
        openEditor: function(id = null) {
            this.editing = id;
            this.$modal.show('email-editor-modal');
        },
        hideEditor: function(refreshRequired = true) {
            this.editing = null;
            this.$modal.hide('email-editor-modal');
            if(this.refresh && refreshRequired) {
                location.reload();
            }
        },
        saveClose(id) {
            this.$emit('saved-template',id);
            this.hideEditor();
        }
    },
    watch: {
        editing: function() {
            this.editor++
        }
    }
}
</script>

<style scoped>
    /deep/ > .vm--modal {
        top: 0 !important;
    }
</style>
