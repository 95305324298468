<template>
    <div>
        <span class="block">Companies: {{ count }}</span>
        <span class="block">Companies Added Today: {{ newCompanies }}</span>
    </div>
</template>

<script>
export default {
    name: "CompanyCounter",
    data() {
        return {
            'count': 0,
            'newCompanies': 0
        }
    },
    mounted() {
        this.updateCount();
    },
    methods: {
        updateCount: function () {
            axios.get('/companies/count').then(response => {
                this.count = response.data.total;
                this.newCompanies = response.data.newCompanies;
            }).then(response => {
                setTimeout(this.updateCount, 300000); // Refresh every 5 Minutes
                //setTimeout(this.updateCount, 10000); // Refresh every 10 seconds
            });
        },
    }
}
</script>

<style scoped>

</style>
