<template>
    <div>
        <span class="block">Registered Companies: {{ count }} [Last Import: {{ last_updated }}]</span>
        <span class="block">Active Registered Companies: {{ activeCount }}</span>
    </div>
</template>

<script>
export default {
    name: "RegisteredCompanyCounter",
    data() {
        return {
            'count': 0,
            'last_updated': 'N/A',
            'activeCount': 0,
        }
    },
    mounted() {
        this.updateCount();
    },
    methods: {
        updateCount: function () {
            axios.get('/companies/registered/count').then(response => {
                this.count = response.data.total;
                this.last_updated = response.data.last_updated;
                this.activeCompanies();
            });
        },
        activeCompanies: function () {
            axios.get('/companies/registered/active-count').then(response => {
                this.activeCount = response.data.total;
            }).then(response => {
                setTimeout(this.updateCount, 300000); // Refresh every 5 Minutes
                //setTimeout(this.updateCount, 10000); // Refresh every 10 seconds
            });
        }
    }
}
</script>

<style scoped>

</style>
