<template>
    <table class="table w-full">
        <thead>
            <tr class="text-left border-b">
                <th class="py-2">Queued At</th>
                <th class="py-2">Client</th>
                <th class="py-2">Trigger Type</th>
                <th class="py-2">Trigger Description</th>
                <th class="py-2 text-right"><i class="fa fa-check-circle text-black"></i></th>
            </tr>
        </thead>
        <tbody class="divide-y divide-gray-300">
            <tr v-for="notification in despatched" :key="notification.id" class="align-text-top text-sm">
                <td class="py-2">{{ notification.created_at }}</td>
                <td class="py-2">{{ notification.client_code }}</td>
                <td class="py-2">{{ notification.trigger.type.charAt(0).toUpperCase() + notification.trigger.type.slice(1) }}</td>
                <td class="py-2">
                    <table class="table w-full">
                        <tr class="align-text-top">
                            <td>{{ notification.trigger.title }}</td>
                        </tr>
                        <tr class="align-text-top text-xs">
                            <td>Contact: {{ notification.contact.lead ? "Lead" : "Customer" }} ({{ notification.contact.portal_user_id }}) - <a target="_blank" :href="'/companies/' + notification.company_id">{{ notification.company ? notification.company.company_name : notification.company_id }}</a></td>
                        </tr>
                    </table>
                </td>
                <td class="py-2 text-right" v-if="notification.portal_received"><i class="fa fa-check-circle text-green-600"></i></td>
                <td class="py-2 text-right" v-else><i class="fa fa-times-circle text-red-600"></i></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "DespatchedTriggersOutput",
    props: {
    },
    data: function() {
        return {
            despatched: null
        }
    },
    mounted() {
        this.updateDespatched();

        setInterval(this.updateDespatched, 60000);
    },
    methods: {
        updateDespatched: function(){
            axios.get('/comms/triggers/despatched')
                .then(response => {
                    this.despatched = response.data.queued_journeys;
                })
        }
    }
}
</script>

<style scoped>

</style>
