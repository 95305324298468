<template>
    <div>
        <div class="w-full flex flex-col align-items-center content-center pt-4 pb-3">
            <label for="email_template_id">Use Email Template:</label>
            <select id="email_template_id" name="email_template_id" v-model="selected_template">
                <option v-for="email in email_templates" :value="email.id">{{ email.title }}</option>
            </select>
            <div class="flex flex-row space-x-4">
                <span class="underline cursor-pointer text-sm mt-1" @click.prevent="$refs.emaileditor.openEditor()">Create Template</span>
                <span v-if="selected_template" class="underline cursor-pointer text-sm mt-1" @click.prevent="$refs.emaileditor.openEditor(selected_template)">Edit Template</span>
            </div>
        </div>
        <email-editor-modal :refresh="false" @saved-template="updateTemplateList" ref="emaileditor" v-bind:user_email="user_email"></email-editor-modal>
    </div>
</template>

<script>
export default {
    name: "EmailEditorLauncher",
    props: {
        email_id: {
            default: null
        },
        user_email: {
            default: ""
        }
    },
    data() {
        return {
            email_templates: null,
            selected_template: this.email_id,
        }
    },
    mounted() {
        this.getTemplatelist();
    },
    methods: {
        getTemplatelist: function() {
            axios.get('/comms/emails')
                .then(response => {
                    this.email_templates = response.data;
                });
        },
        updateTemplateList: function (id) {
            axios.get('/comms/emails')
                .then(response => {
                    this.email_templates = response.data;
                    this.selected_template = id;
                });
        }
    }
}
</script>

<style scoped>

</style>
