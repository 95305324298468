<template>
        <div>
            <dl class="bg-gray-50 divide-y divide-gray-200 py-1">
                <div v-for="(value, key) in fields" class="bg-gray-50 px-2 py-1 sm:grid sm:grid-cols-2 sm:px-6">
                    <dt class="text-xs leading-5 font-medium text-secondary">
                        {{ key }}
                    </dt>
                    <dd class="text-xs leading-5 text-gray-900 sm:mt-0">
                        <div v-if="isJson(value)">
                            <dl class="divide-y divide-gray-200">
                                <div v-for="(value, key) in value" class="sm:grid sm:grid-cols-2">
                                    <dt class="font-medium text-secondary">{{ key }}</dt>
                                    <dd>{{ formatValues(value) }}</dd>
                                </div>
                            </dl>
                        </div>
                        <div v-else>
                            {{ value }}
                        </div>
                    </dd>
                </div>
            </dl>
        </div>
</template>

<script>
export default {
    name: "EnrichedCompanyFinancials",
    props: ['company'],
    data: function() {
        return {
        }
    },
    computed: {
        fields: function() {
            return _.pickBy(this.company, function(d,key) {
                const excludeKeys = [
                    'updated_at',
                    'created_at',
                    'last_enriched',
                ];
                return d !== null && !excludeKeys.includes(key);
            });
        }
    },
    methods: {
        isJson: function(item){
            item = typeof item !== "string"
                ? JSON.stringify(item)
                : item;

            try {
                item = JSON.parse(item);
            } catch (e) {
                return false;
            }

            return typeof item === "object" && item !== null;
        },
        formatValues: function(value){
            if(this.isFloat(value)){
                return Math.round((value + Number.EPSILON) * 100) / 100;
            }
            if(!value && value !== 0){
                return '-';
            }
            return value;
        },
        isFloat: function(n) {
            return Number(n) === n && n % 1 !== 0;
        }
    }
}
</script>
