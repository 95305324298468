<template>
    <div class="bg-white overflow-hidden">
        <div class="px-4 py-5 border-b border-gray-200 sm:px-6 h-20 items-center flex">
            <p class="mb-0 max-w-2xl text-sm leading-5 text-gray-500">
                <strong>Source:</strong> Companies House
            </p>
        </div>
        <div>
            <dl class="bg-gray-50 divide-y divide-gray-200 py-1">
                <div v-for="(value, key) in fields" class="bg-gray-50 px-2 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-xs leading-5 font-medium text-secondary">
                        {{ key }}
                    </dt>
                    <dd class="text-xs leading-5 text-gray-900 sm:mt-0">
                        {{ value }}
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisteredCompany",
    props: ['company'],
    computed: {
        fields: function() {
            return _.pickBy(this.company, function(d,key) {
                const excludeKeys = [
                    'updated_at',
                    'created_at',
                    'last_enriched',
                ];
                return d !== null && !excludeKeys.includes(key);
            });
        }
    }
}
</script>
