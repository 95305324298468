<template>
    <div>
        <multiselect :placeholder="placeholder" v-model="value" :options="options" :multiple="true" :clear-on-select="false" label="title"
                     track-by="id"></multiselect>
        <input type="hidden" :id="name" :name="name" :value="cats">
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "SelectArticleCategories",
    components: {Multiselect},
    data() {
        return {
            value: null,
            options: []
        }
    },
    props: {
        name: {
            type: String,
            default: function () {
                return "categories"
            }
        },
        placeholder: {
            type: String,
            default: function () {
                return "Select Category(s)"
            }
        },
        article_id: {
            type: Number,
            default: function () {
                return null
            }
        },
        existing_categories: {
            type: String,
            default: function () {
                return null
            }
        },
    },
    mounted() {
        axios.get('/category')
            .then(response => {
                this.options = response.data;
            })

        if(this.article_id) {
            axios.post('/categorised', {
                id: this.article_id
            }).then(response => {
                this.value = response.data;
            });
        }

        if(this.existing_categories) {
            let cats = this.existing_categories.split(",");
            axios.post('/categorised', {
                cats: cats,
            }).then(response => {
                this.value = response.data;
            });
        }
    },
    computed: {
        cats() {
            let selected_cats = [];
            if (this.value) {
                this.value.forEach(function (cat) {
                    selected_cats.push(cat.id);
                })
            }
            return selected_cats;
        }
    }
}
</script>

<style>

</style>
