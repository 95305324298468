<template>
    <div>
        <span @click.prevent="openTestDialog" class="inline-block bg-secondary text-white rounded-full p-3 cursor-pointer hover:text-secondary hover:bg-white ease-in-out duration-500 transition-all" title="Send Test">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                        </span>
        <vmodal name="email-test-modal" :reset="true" :classes="['p-6']" :height="'auto'">
            <label for="recipient" class="block">Send test email to:</label>
            <input type="text" id="recipient" v-model="recipient" />
            <label for="user" class="block mt-3">Select User for sample data:</label>
            <select id="user" v-model="user_sample_data">
                <option :value="user.id" v-for="user in client.user_sample">{{ user.name || '--' }} <template v-if="user.is_customer">[C]</template><template v-if="user.company">[B]</template></option>
            </select>
            <input type="checkbox" v-model="as_recommendation" id=""> Test template as recommendation?
            <p class="text-xs pt-2">Important: Sending this test will automatically save the template currently being edited.<br/>
                <strong>[C]</strong> Customer<br/>
                <strong>[B]</strong> Has Business Data</p>
            <button @click.prevent="sendTest" type="button" class="btn btn-primary mt-4">Send Test</button>
        </vmodal>
    </div>
</template>

<script>
export default {
    name: "EmailTest",
    props: {
        client: {
            required: true
        },
        user_email: {
            default: ""
        }
    },
    data() {
        return {
            user_sample_data: null,
            acq: false,
            recipient: this.user_email,
            as_recommendation: false
        }
    },
    methods: {
        openTestDialog: function() {
            if(this.client.user_sample) {
                this.user_sample_data = this.client.user_sample[0].id
            }
            this.$modal.show('email-test-modal');
        },
        sendTest: function() {
            this.$modal.hide('email-test-modal');
            this.$emit('sendTest',[this.user_sample_data,this.acq,this.recipient, this.as_recommendation]);
        }
    },
}
</script>

<style scoped>

</style>
