<template>
    <iframe :srcdoc="html_preview"/>
</template>

<script>

export default {
    name: "EmailEditorPreview",
    props: {
        compiled_html_preview: {
            required: true
        }
    },
    mounted(){
    },
    computed: {
        html_preview: function(){
            return this.compiled_html_preview;
        }
    }
}
</script>

<style scoped>

</style>
