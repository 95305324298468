/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
window.dayjs = require('dayjs');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('company-counter', require('./components/CompanyCounter.vue').default);
Vue.component('company-enrichment-counter', require('./components/EnrichmentStats.vue').default);
Vue.component('company-profile', require('./components/CompanyProfile.vue').default);
Vue.component('registered-company', require('./components/registered-companies/RegisteredCompany.vue').default);
Vue.component('enriched-company-profile', require('./components/registered-companies/EnrichedCompanyProfile.vue').default);
Vue.component('enriched-company-financials', require('./components/registered-companies/EnrichedCompanyFinancials.vue').default);
Vue.component('enriched-company-officers', require('./components/registered-companies/EnrichedCompanyOfficers.vue').default);
Vue.component('registered-company-counter', require('./components/RegisteredCompanyCounter.vue').default);
Vue.component('tabs', require('./components/registered-companies/Tabs').default);
Vue.component('tab', require('./components/registered-companies/Tab').default);
Vue.component('select-tags', require('./components/SelectTags').default);
Vue.component('modal', require('./components/Modal').default);
Vue.component('modal-open', require('./components/ModalOpen').default);
Vue.component('pagination', require('./components/Pagination').default);
Vue.component('media-input', require('./components/media/MediaInput').default);
Vue.component('nurture-question-edit', require('./components/NurtureQuestionEdit').default);
Vue.component('company-trigger-status', require('./components/trigger_management/DisableCompanyTriggers').default);
Vue.component('company-changes', require('./components/trigger_management/CompanyChangesInspected').default);
Vue.component('despatched-triggers', require('./components/trigger_management/DespatchedTriggersOutput').default);
Vue.component('trigger-form', require('./components/trigger_management/TriggerForm').default);
Vue.component('trigger-conditions-realtime', require('./components/trigger_management/TriggerRealtime').default);
Vue.component('trigger-conditions-reminder', require('./components/trigger_management/TriggerReminder').default);
Vue.component('trigger-test', require('./components/trigger_management/TriggerTest').default);
Vue.component('date-picker', require('./components/DatePicker').default);
Vue.component('select-categories', require('./components/SelectArticleCategories').default);
Vue.component('broadcast-test', require('./components/BroadcastTest').default);
Vue.component('broadcast-count', require('./components/BroadcastContactCount').default);
Vue.component('email-editor', require('./components/EmailEditorForm').default);
Vue.component('email-editor-modal', require('./components/EmailEditorModal').default);
Vue.component('email-editor-launcher', require('./components/EmailEditorLauncher').default);
Vue.component('email-create-button', require('./components/EmailCreateButton').default);
Vue.component('email-delete-button', require('./components/EmailDeleteButton').default);
Vue.component('ckeditor-input', require('./components/CKEditor').default);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast,{
    position: "bottom-right",
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    closeOnClick: true,
    timeout:3000
});

import VModal from 'vue-js-modal'
Vue.use(VModal, { componentName: 'vmodal' })

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

// autoclose alerts/flash banner
if($('.alert').length) {
    setTimeout(function () {
        $(".alert").alert('close');
    }, 15000);
}
