<template>
    <div class="form-group media-input">
        <label v-bind:for="name" class="block">{{ showLabel }}</label>
        <div class="border bg-light media-input-actions">
        <div v-if="loadedMediaId">
            <media-preview v-bind:show-preview="showPreview" v-bind:media-type="mediaType" v-bind:previewsrc="src" @replaceMedia="replaceMedia()"></media-preview>
        </div>
        <div v-else>
            <div v-if="!activeSelector" class="w-100 text-center py-5 px-3">
                <button type="button" class="btn btn-primary btn-rounded" @click="activeSelector = 'new'">Upload {{ mediaType }}</button>
                <button type="button" class="btn btn-outline-primary btn-rounded" @click="activeSelector = 'library'">Select Existing</button>
            </div>

            <MediaSelect v-if="activeSelector === 'library'" @mediaSelected="updateSelectedMedia($event)" @cancel="activeSelector = null"></MediaSelect>

            <div :class="{ 'flex flex-row items-center': !showPreview }">
                <MediaUpload v-if="activeSelector === 'new'" @mediaSelected="updateSelectedMedia($event)" :class="{ 'flex-grow text-left': !showPreview, 'text-center': showPreview }"></MediaUpload>
                <div v-if="activeSelector" class="w-100 py-3 border-top text-right" style="padding-left: 0.75rem; padding-right: 0.75rem;">
                    <button type="button" class="btn btn-outline-dark btn-sm btn-rounded" @click="activeSelector = null">Reset</button>
                </div>
            </div>
        </div>
        </div>
        <input type="hidden" v-bind:name="name" v-bind:value="loadedMediaId">
    </div>
</template>

<script>

import MediaUpload from './MediaUpload.vue';
import MediaSelect from './MediaSelect.vue';
import MediaPreview from './MediaPreview.vue';

export default {
    name: "MediaInput",
    props: {
        name: {
            required: true
        },
        mediaType: {
            default: "Image"
        },
        mediaId: {
            default: null
        },
        source: {
            default: null
        },
        label: {
            default: null
        },
        showPreview: {
            default: true
        }
    },
    components: {
        MediaUpload,
        MediaSelect,
        MediaPreview
    },
    data() {
        return {
            activeSelector: null,
            src: this.source,
            loadedMediaId: this.mediaId
        }
    },
    mounted: function(){
        if(this.loadedMediaId && !this.src){
            axios.get('/media/' + this.loadedMediaId)
            .then(res => {
                this.src = '/media/' + res.data.filename;
            })
        }
    },
    methods: {
        updateSelectedMedia($event){
            this.loadedMediaId = $event[0];
            this.src = '/media/' + $event[1];
            this.activeSelector = null;
        },
        replaceMedia: function(){
            this.loadedMediaId = null;
            this.src = null;
            this.activeSelector = null;
        }
    },
    computed: {
        showLabel: function(){
            if(this.label){
                return this.label;
            }
            return 'Media Upload';
        }
    },
    watch: {
        loadedMediaId: function(){
            this.$emit('mediaUpdated',[this.loadedMediaId,this.src]);
        }
    }
}
</script>

<style scoped>

</style>
