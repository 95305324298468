<template>
    <div>
        <a class="btn bg-primary py-2 px-4 cursor-pointer" @click.prevent="$parent.$refs.emaileditor.openEditor()">New Email Template</a>
    </div>
</template>

<script>
export default {
    name: "EmailCreateButton",
}
</script>

<style scoped>

</style>
