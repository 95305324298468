<template>
    <div>
        <multiselect :placeholder="placeholder" v-model="value" :options="options" :multiple="true" :clear-on-select="false" label="label"
                     track-by="id" v-on:remove="notifyFormChange" v-on:close="notifyFormChange"></multiselect>
        <input type="hidden" :id="name" :name="name" :value="tags">
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "SelectTags",
    components: {Multiselect},
    data() {
        return {
            value: null,
            options: []
        }
    },
    props: {
        name: {
            type: String,
            default: function () {
                return "tags"
            }
        },
        placeholder: {
            type: String,
            default: function () {
                return "Select Tag(s)"
            }
        },
        model_type: {
            type: String,
            default: function () {
                return ""
            }
        },
        model_id: {
            type: Number,
            default: function () {
                return null
            }
        },
        existing_tags: {
            type: String,
            default: function () {
                return null
            }
        },
    },
    mounted() {
        axios.get('/tag')
            .then(response => {
                this.options = response.data;
            })

        if(this.model_id) {
            axios.post('/tagged', {
                class: this.model_type,
                id: this.model_id
            }).then(response => {
                this.value = response.data;
            });
        }

        if(this.existing_tags) {
            let tagged = this.existing_tags.split(",");
            axios.post('/tagged', {
                tagged: tagged,
            }).then(response => {
                this.value = response.data;
            });
        }
    },
    computed: {
        tags() {
            let selected_tags = [];
            if (this.value) {
                this.value.forEach(function (tag) {
                    selected_tags.push(tag.id);
                })
            }
            return selected_tags;
        }
    },
    methods: {
        notifyFormChange: function() {
            setTimeout(function(){
                this.$root.$emit('form-updated');
            }.bind(this), 1000)
        }
    }
}
</script>

<style>
    input[type=text].multiselect__input {
        background: none !important;
    }
</style>
