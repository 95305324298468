<template>
    <div>
        <p class="text-xs font-semibold">Please save all changes before testing.</p>
        <label for="portal">Select Portal for Broadcast test:</label>
        <select id="portal" name="portal" v-model="portal" class="w-full">
            <option v-for="portal in portals" :value="portal.id">{{ portal.name }}</option>
        </select>
        <label>Send test to email address:</label>
        <input id="email" name="email" type="text" v-model="email">
        <button v-if="email && portal" class="btn btn-primary w-full block mt-3 mb-3" v-on:click.prevent="testbroadcast">Test broadcast</button>
        <div v-if="sent" class="text-xs text-green-600">Success!</div>
        <div v-if="error" class="text-xs text-red-600">Please try again.</div>
    </div>
</template>
<script>

export default {
    name: "BroadcastTest",
    props: {
        users_email:null,
        broadcast:{
            required:true
        }
    },
    data: function () {
        return {
            portals:null,
            email:this.users_email ?? "",
            portal:null,
            sent:false,
            error:false
        }
    },
    mounted() {
        axios.post('/portals/fetch').then(response => {
            this.portals = response.data.portals;
        }).then(response => {
            this.portal = this.portals[0].id;
        });
    },
    methods: {
        testbroadcast: function(){
            // update to AP with broadcast content, which add Contact to Smart Segment and broadcasts journey
            axios.post('/comms/broadcasts/test',{
                broadcast: this.broadcast,
                portal: this.portal,
                email: this.email
            }).then(response => {
                this.sent = true;
                let self = this;
                setTimeout(function() {
                    self.sent = false;
                }, 5000);
            }).catch(error => {
                this.error = true;
                let self = this;
                setTimeout(function() {
                    self.error = false;
                }, 5000);
            })
        }
    }
}
</script>

<style scoped>

</style>
