<template>
    <div id="reminder-trigger-conditions">
        <label class="inline-block w-full mt-3" for="days_before">Trigger reminder X Days</label>
        <input type="number" name="days_before" id="days_before" v-model="days_before">

        <label class="inline-block w-full mt-3" for="date_field">Before Date</label>
        <select name="date_field" id="date_field" v-model="date_field">
            <option v-for="field in options.dateFields" :value="field">{{ field }}</option>
        </select>

    </div>
</template>

<script>
export default {
    name: "TriggerRealtime",
    props: {
        trigger: {
            default: function () {
                return {
                    triggerable:null
                }
            },
        },
        options: {
            default: function () {
                return []
            },
        }
    },
    data: function() {
        return {
            date_field: null,
            days_before: 30
        }
    },
    mounted() {
        if(this.trigger) {
            this.date_field = this.trigger.triggerable.date_field;
            this.days_before = this.trigger.triggerable.days_before ?? 30;
        }
    }
}
</script>

<style scoped>

</style>
