<template>
    <div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50 media-select">
        <div class="bg-white rounded shadow-lg w-10/12 md:w-7/12 xl:w-1/3 p-6">
            <div class="pb-3 flex justify-between items-center">
                <h3 class="font-semibold text-lg m-0">Select Media</h3>
                <button class="text-black close-modal" @click.prevent="cancel">&cross;</button>
            </div>
            <div class="flex flex-row flex-wrap" style="max-height: 640px; overflow-y: auto;">
                <div v-for="media in mediaList" class="border border-white hover:border-gray-50 hover:cursor-pointer hover:opacity-75 w-1/3 md:w-1/4 lg:w-1/3 xl:w-1/4" @click="mediaSelected(media.id,media.filename)"
                     v-bind:style="{ backgroundImage: 'url(\'/media/' + media.filename + '\')', backgroundPosition: 'center', backgroundSize: 'fill', backgroundRepeat: 'no-repeat', height: 100 + 'px' }">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MediaSelect",
    data() {
        return {
            mediaList: null
        }
    },
    mounted: function () {
        axios.get('/media/list')
            .then(res => {
                this.mediaList = res.data;
            })
    },
    methods: {
        mediaSelected: function (id, filename) {
            this.$emit('mediaSelected', [id, filename]);
        },
        cancel: function() {
            this.$emit('cancel');
        }
    }
}
</script>

<style scoped>
td {
    vertical-align: middle;
}
</style>
