<template>
    <div class="w-100 bg-light py-5 px-3 media-upload">
        <div class="input-group w-75 mx-auto">
            <div class="custom-file">
                <input type="file" @change="onFileSelected" class="custom-file-input" id="inputGroupFile">
                <label v-if="!selectedFile" class="custom-file-label" for="inputGroupFile">Choose File</label>
                <label v-else class="custom-file-label" for="inputGroupFile">{{ uploadLabel }}</label>
            </div>
        </div>
        <div v-if="selectedFile" class="pt-4">
            <button class="btn btn-success btn-rounded" @click="onUpload" type="button">Upload</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MediaUpload",
    data () {
        return {
            selectedFile: null
        }
    },
    methods: {
        onFileSelected: function (event){
            this.selectedFile = event.target.files[0]
        },
        onUpload: function (){
            const fd = new FormData();
            fd.append('file', this.selectedFile, this.selectedFile.name);
            fd.append('banner', '1');
            axios.post('/media/upload', fd)
            .then(res => {
                this.$emit('mediaSelected', [res.data.id,res.data.filename])
                this.selectedFile = null;
            })
        }
    },
    computed: {
        uploadLabel: function(){
            if (this.selectedFile.name.length <= 42) {
                return this.selectedFile.name
            }
            // Return str truncated with '...' concatenated to the end of str.
            return this.selectedFile.name.slice(0, 42) + '...'
        }
    }
}
</script>

<style scoped>

</style>
