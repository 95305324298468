<template>
    <span @click="showModal(modal)">
        <slot></slot>
    </span>
</template>

<script>
export default {
name: "ModalOpen",
    props: ['modal'],
    methods: {
        showModal: function (modal){
            this.$parent.$refs[modal].openModal();
        }
    }
}
</script>

<style scoped>

</style>
