<template>
        <div class="flex">
        <form v-bind:action="'/nurture/question/save' + question_id" method="POST" class="w-full">
            <input type="hidden" name="_token" v-bind:value="csrftoken">
            <div class="form-group">
                <label class="label" for="question">Question</label>
                <input class="form-control" id="question" name="question" v-model="question_text" type="text">
            </div>
            <div class="form-group mt-4">
                <label class="label" for="custom_field_label">Custom Field Label</label>
                <input class="form-control" id="custom_field_label" name="custom_field_label" v-model="custom_field_label" type="text">
            </div>
            <div class="form-group">
                <label class="label" for="overrides">Response Overrides Customer Field</label>
                <select class="form-control" id="overrides" name="overrides" v-model="overrides">
                    <option value="">None</option>
                    <option v-for="field in JSON.parse(fields)" :value="field">{{ field }}</option>
                </select>
                <hr>
            </div>
            <div class="form-group">
                <label class="label" for="question_type">Question Format</label>
                <select class="form-control" id="question_type" name="question_type" v-model="question_type">
                    <option value="1">Text</option>
                    <option value="2">Select (Multiple Choice)</option>
                    <option value="3">Number</option>
                    <option value="4">Date</option>
                    <option value="5">Boolean</option>
                </select>
                <hr>
            </div>
            <div class="form-group mt-8" v-if="question_type === 2">
                <hr>
                <label class="block w-100 py-4 label">Options</label>

                <div class="input-group flex">
                    <input type="text" class="form-control" v-model="new_option" placeholder="Add New Option">
                    <span class="btn btn-primary cursor-pointer ml-2" v-on:click="add_option">Add</span>
                </div>

                <div v-for="(option,index) in options" class="form-row pt-2 flex w-full">
                    <div class="flex-grow">
                        <input type="text" class="form-control" :value="option.label" readonly disabled>
                        <input type="hidden" :name="'options[' + index + ']'" :id="'options[' + index + ']'" v-bind:value="option.label">
                        <select-tags class="mt-2" v-bind:existing_tags="option.tags" v-bind:placeholder="'Select Tag(s) to associate with User given this response'" v-bind:name="'option_tags[' + index + ']'"></select-tags>
                    </div>
                    <div class="ml-2">
                        <button class="btn bg-red-600" type="button" v-on:click="remove(index)">Remove</button>
                    </div>
                </div>
                <div class="form-row align-items-center mt-4">
                    <div class="col-auto my-1">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="other_option" name="other_option" v-model="other_option">
                            <label class="form-check-label pr-3" for="other_option">
                                Show "Other" option?
                            </label>
                        </div>
                    </div>
                    <div class="col my-1">
                        <label class="sr-only" for="other_label">Label for other input?</label>
                        <input type="text" class="form-control" name="other_label" id="other_label" placeholder='Label for "Other" option' v-model="other_label">
                    </div>
                </div>

            </div>

            <div class="align-items-left mt-4">
                <label class="label" for="tags">Show Question to Users with Tags?</label>
                <select-tags v-bind:model_type="'App\\Models\\NurtureQuestion'" v-bind:model_id="question_id_int"></select-tags>
            </div>

            <button type="submit" class="btn btn-primary mt-6">Save Changes</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: "nurture-question-edit",
        props: {
            question: {
                type: String,
                default: function () {
                    return ""
                }
            },
            csrftoken: {
                type: String,
                default: function () {
                    return ""
                }
            },
            fields: {
                required: true
            }
        },
        data() {
            return {
                question_being_edited: {},
                question_type: 1,
                other_option: false,
                other_label: "",
                options: [],
                new_option: "",
                question_text: "",
                custom_field_label: "",
                overrides: ""
            }
        },
        created: function () {
            if(this.question) {
                this.question_being_edited = JSON.parse(this.question);
                this.question_type = this.question_being_edited.question_type;
                this.other_option = this.question_being_edited.other_option;
                this.other_label = this.question_being_edited.other_label;
                this.options = this.question_being_edited.options;
                this.question_text = this.question_being_edited.question;
                this.custom_field_label = this.question_being_edited.custom_field_label;
                this.overrides = this.question_being_edited.overrides;
            }
        },
        computed: {
          question_id: function () {
              if(this.question_being_edited.id){
                  return '/' + this.question_being_edited.id;
              }
              return '';
          },
            question_id_int: function () {
                if(this.question_being_edited.id){
                    return this.question_being_edited.id;
                }
                return null;
            }
        },
        methods: {
            remove: function (index) {
                this.options.splice(index,1);
            },
            add_option: function() {
                if(this.new_option !== '') {
                    this.options.push({'label': this.new_option});
                    this.new_option = '';
                }
            }
        }
    }
</script>
