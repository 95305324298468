<template>
    <div class="broadcast-count flex">
        <span class="mr-2">{{ contacts }} Contact(s) in Audience</span> <button type="button" class="fa fa-refresh outline-none focus:outline-none" @click="refresh"></button>
    </div>
</template>

<script>
export default {
    name: "BroadcastCount",
    data() {
        return {
            contacts: 0,
        }
    },
    mounted() {
        this.refresh();
    },
    created() {
        this.$root.$on('form-updated', (param) => {
            this.refresh();
        })
    },
    methods: {
        refresh: function(){

            let clients = [];
            $('input[name="clients[]"]:checked').each(function() {
                clients.push($(this).val());
            });

            let required = [];
            $('input[name="requires[]"]:checked').each(function() {
                required.push($(this).val());
            });

            let tags = $('input[name="tags"]').val();
            tags = tags === "" ? [] : [tags];

            axios.post('/comms/broadcasts/audience',{
                required:required,
                clients:clients,
                tags:tags
            }).then(response => {
                this.contacts = response.data.contacts;
            });
        }
    }
}
</script>

<style scoped>

</style>
