<template>
    <div class="ml-auto flex items-center leading-none">
        <div class="mr-4">
            <span class="mr-1"><strong>Updates Inspected Today:</strong></span>{{ updates }}
        </div>
        <div>
            <span class="mr-1"><strong>Journeys Queued Today:</strong></span>{{ journeys }}
        </div>
    </div>
</template>

<script>
export default {
    name: "CompanyChangesInspected",
    data: function() {
        return {
            updates: 0,
            journeys: 0
        }
    },
    mounted() {
        this.updatesCheckedCount();
        setInterval(this.updatesCheckedCount, 15000);
    },
    methods: {
        updatesCheckedCount: function (){
            axios.get('/comms/triggers/checks')
            .then(response => {
                this.updates = response.data.count;
                this.journeys = response.data.journeys;
            })
        }
    }
}
</script>

<style scoped>

</style>
