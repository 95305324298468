<template>
    <div>
        <ckeditor @namespaceloaded="onNamespaceLoaded" v-model="content" :config="editorConfig"></ckeditor>
        <textarea :name="field" v-html="content" style="display: none;"></textarea>
    </div>
</template>

<script>

export default {
    name: "CKEditor",
    props: {
        editing: {
            default: null,
        },
        field: {
            default: "body"
        },
        show_custom_fields: {
            default: true
        },
        custom_fields: {
            type: Array,
            default() {
                return []
            }
        },
        custom_fields_dates: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            content: this.editing,
            editorConfig: {
                'extraPlugins': ['uploadimage',...(this.show_custom_fields ? ['bs-placeholder'] : [])],
                'uploadUrl': '/media/upload',
                'filebrowserUploadUrl': '/media/upload'
            }
        };
    },
    methods: {
        onNamespaceLoaded: function(CKEDITOR){
            let componentdata = this;
            CKEDITOR.plugins.add( 'bs-placeholder', {
                icons: 'timestamp',
                init: function( editor ) {
                    editor.addCommand( 'selectPlaceholder', new CKEDITOR.dialogCommand('selectPlaceholderDialog'));
                    editor.ui.addButton( 'Placeholder', {
                        label: 'Insert Placeholder',
                        command: 'selectPlaceholder',
                        toolbar: 'insert'
                    });

                    CKEDITOR.dialog.add('selectPlaceholderDialog', function(editor) {

                        let cfields = [];
                        componentdata.custom_fields.forEach(function(field){
                            cfields.push([field]);
                        });

                        return {
                            title: 'Insert Placeholder',
                            minWidth: 300,
                            minHeight: 200,
                            contents: /* tab of dialog*/ [{
                                id: 'tab1',
                                label: 'Settings',
                                elements: [
                                    {
                                        id: "placeholderAttribute",
                                        type: "select",
                                        // widths: ["35%", "65%"],
                                        // style: "width:90px",
                                        label: 'Placeholder Attribute: ',
                                        "default": "",
                                        items: cfields,
                                        validate: CKEDITOR.dialog.validate.notEmpty("Placeholder Attribute is Required"),
                                    },
                                    {
                                        type: 'text',
                                        id: 'placeholderDefault',
                                        label: 'Default/Fallback Value: ',
                                    }
                                ]
                            }],
                            onOk: function() { /*event trigger when click OK Button*/
                                var dialog = this;
                                /*Get all input Values*/
                                var placeAttribute = dialog.getValueOf('tab1', 'placeholderAttribute');
                                var placeDefault = dialog.getValueOf('tab1', 'placeholderDefault');
                                editor.insertHtml( componentdata.preparePlaceholder(placeAttribute,componentdata.custom_fields_dates.includes(placeAttribute),placeDefault) );

                            }
                        };
                    }); //dialog end
                }
            });

        },
        preparePlaceholder: function(field, date = false, defaultValue = null){
            //field = field.toLowerCase().replace(/[^0-9a-z.]/gi, '');

            let placeholder = '{{ ' + field;

            if(date) {
                placeholder = placeholder + ' | date: "%d-%m-%Y %H:%M"';
            }

            if(defaultValue) {
                placeholder = placeholder + ' | default: "' + defaultValue + '"';
            }

            return placeholder + ' }}';
        },
    },
    watch: {
        content: function(content) {
            this.$emit('contentUpdated', content);
        }
    }
}

</script>

<style scoped>

</style>
