<template>
    <div :class="{ 'opacity-50':loading, 'opacity-100':!loading }" class="transition-opacity duration-500 ease-in-out" spellcheck="false">
        <div class="w-full flex-grow bg-white flex flex-row h-full">
            <div class="flex-grow border-r flex flex-col xl:relative">
                <div class="bg-gray-200 flex-grow text-center relative">
                    <email-editor-preview class="h-full w-full overflow-y-scroll" :key="lastChange" v-bind:compiled_html_preview="compiled_html_preview"></email-editor-preview>
                </div>
                <email-test @sendTest="saveAndSendTest" v-if="client" v-bind:client="client" v-bind:user_email="user_email" class="hidden xl:inline-block xl:absolute xl:absolute right-0 mr-8 bottom-0 mb-24"></email-test>
                <span @click.prevent="copySource" class="hidden xl:inline-block xl:absolute right-0 mr-8 bottom-0 mb-8 bg-secondary text-white rounded-full p-3 cursor-pointer hover:text-secondary hover:bg-white ease-in-out duration-500 transition-all" title="Copy Source">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                            </svg>
                        </span>
            </div>
            <div class="max-w-2xl flex flex-col overflow-y-scroll overflow-x-hidden">
                <div class="text-right flex items-center border-b p-5">
                    <div class="text-left flex-grow"></div>
                    <a @click.prevent="$emit('cancel')" class="btn text-gray-700 bg-transparent border-0 outline-none hover:bg-transparent hover:border-0 cursor-pointer">Cancel</a>
                    <button @click="saveTemplate" class="btn btn-primary" type="button">Publish Changes</button>
                </div>
                <div class="flex flex-col lg:flex-row border-b">
                    <div class="flex-grow p-3 flex-row flex">
                        <label class="w-48 mb-0 leading-6">Preview Client:</label>
                        <select class="transparent flex-grow" @change="updateClient($event)">
                            <option v-for="s_client in clients" :value="s_client.id" :selected="active_client_id === s_client.id">{{ s_client.client_name }}</option>
                        </select>
                    </div>
                    <div class="w-full lg:w-6/12 lg:border-l p-3"><label>Email Sender:</label> <span class="text-gray-500 text-sm">{{ sender }}</span></div>
                </div>
                <div class="w-full p-3 flex-row flex"><label class="mb-0 leading-6">Email Title (Internal):</label> <input class="transparent flex-grow" type="text" v-model="title" placeholder="Enter email title..."></div>
                <div class="w-full p-3 flex-row flex border-t border-b"><label class="mb-0 leading-6">Email Subject:</label> <input class="transparent flex-grow" type="text" v-model="subject" placeholder="Enter email subject..."></div>
                <div class="w-full">
                    <media-input v-if="client" name="banner_media_id" media-type="Email Banner" :media-id="banner_id" @mediaUpdated="updateBanner" :show-preview="false"></media-input>
                </div>
                <div class="w-full">
                    <ckeditor-input :custom_fields="client.custom_fields.fields" :custom_fields_dates="client.custom_fields.date_fields" v-if="client" field="body" id="body" :editing="email_html" @contentUpdated="updateHtml" placeholder="Enter email content here..."></ckeditor-input>
                </div>
                <div class="w-full border-b items-center content-center text-center flex flex-row p-3 justify-center">
                    <span @click="$refs['button_designer_modal'].openModal();" class="cursor-pointer btn btn-primary">Configure CTA Button</span>
                    <span @click="removeButton" class="cursor-pointer ml-3 btn bg-white text-secondary border-0 hover:bg-white" title="Remove CTA Button" v-if="buttonConstructor.url && buttonConstructor.label">Reset Button</span>
                </div>
                <div class="w-full">
                    <ckeditor-input :custom_fields="client.custom_fields.fields" :custom_fields_dates="client.custom_fields.date_fields" v-if="client" field="html2" id="html2" :editing="email_html2" @contentUpdated="updateHtml2" placeholder="Enter secondary email content here..."></ckeditor-input>
                </div>
                <div class="w-full border-b p-3 flex-col flex-grow flex">
                    <label>Plain Text Content:</label>
                    <textarea spellcheck="false" v-model="email_text" class="transparent w-full block -mx-4" placeholder="Enter plain text version of email..."></textarea>
                </div>
            </div>
        </div>
        <modal ref="button_designer_modal" container-class="button-designer-modal-container" title="Configure Call-to-Action">
            <div class="px-3">
                <label class="pt-3 block" for="button_label">Button Label</label>
                <input type="text" id="button_label" name="button_label" v-model="buttonConstructor.label">

                <label class="pt-3 block" for="button_url">Button Link</label>
                <input type="text" id="button_url" name="button_url" v-model="buttonConstructor.url">

                <div class="flex flex-row w-full space-x-4">
                    <div class="flex-grow">
                        <label class="pt-3 block" for="button_type">Button Type</label>
                        <select id="button_type" name="button_type" v-model="buttonConstructor.type">
                            <option value="primary">Primary</option>
                            <option value="secondary">Secondary</option>
                        </select>
                    </div>

                    <div class="flex-grow">
                        <label class="pt-3 block" for="button_align">Button Position</label>
                        <select id="button_align" name="button_align" v-model="buttonConstructor.align">
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </select>
                    </div>
                </div>

                <!-- BUTTON PREVIEW -->
                <div class="w-full pb-10 mt-4 text-sm" v-html="buttonPreview"></div>

                <a href="#" @click.prevent="updateButton" class="btn btn-primary">Save</a>
                <a href="#" @click.prevent="$refs['button_designer_modal'].closeModal()"
                   class="btn text-gray-700 bg-transparent border-0 outline-none hover:bg-transparent hover:border-0 cursor-pointer mt-2 lg:mt-0 ml-0">Cancel</a>
            </div>
        </modal>
    </div>
</template>

<script>
import EmailEditorPreview from "./EmailEditorPreview";
import MediaSelect from "./media/MediaSelect";
import EmailTest from "./EmailTest";

export default {
    name: "EmailEditorForm",
    components: {EmailTest, MediaSelect, EmailEditorPreview},
    props: {
        email_id: {
            default: null
        },
        user_email: {
            default: ""
        }
    },
    data() {
        return {
            clients: [],
            active_client_id: null,
            client: null,
            loading: true,
            banner_id: null,
            banner_src: null,
            email_html: '',
            email_html2: '',
            email_text: '',
            title: '',
            subject: '',
            editingId: this.email_id,
            compiled_html_preview: '',
            lastChange: Date.now(),
            buttonConstructor: {
                align: 'center',
                type: 'primary',
                label: '',
                url: ''
            }
        }
    },
    mounted() {
        this.getClients();
        if(this.email_id) {
            axios.get('/comms/emails/' + this.email_id)
                .then(response => {
                    let email = response.data;
                    this.email_html = email.html;
                    this.email_html2 = email.html2;
                    this.email_text = email.text;
                    this.subject = email.subject;
                    this.title = email.title;
                    this.banner_id = email.banner_media_id;
                    this.buttonConstructor.type = email.button_type;
                    this.buttonConstructor.align = email.button_align;
                    this.buttonConstructor.url = email.button_link;
                    this.buttonConstructor.label = email.button_label;
                })
                .catch(err => { console.log(err) })
                .finally(response => {
                    this.loading = false;
                    this.lastChange++;
                });
        } else {
            this.loading = false;
        }
    },
    methods: {
        updateHtml: function(html) {
          this.email_html = html;
        },
        updateHtml2: function(html2) {
            this.email_html2 = html2;
        },
        updateButton: function(){
            this.updatePreview();
            this.$refs['button_designer_modal'].closeModal();
        },
        removeButton: function(){
            this.buttonConstructor.url = this.buttonConstructor.label = '';
            this.updatePreview();
        },
        updatePreview: function()
        {
              axios.post('/comms/emails/preview',{
                  id: this.editingId,
                  brand: this.client.brand,
                  html: this.email_html,
                  html2: this.email_html2,
                  subject: this.subject,
                  text: this.email_text,
                  button_type: this.buttonConstructor.type,
                  button_align: this.buttonConstructor.align,
                  button_link: this.buttonConstructor.url,
                  button_label: this.buttonConstructor.label,
                  banner_media_id: this.banner_id
              }).then(response => {
                  this.compiled_html_preview = response.data;
                  this.lastChange++;
              });
        },
        saveTemplate(exit_on_saved = true) {
            this.loading = true;
            axios.put('/comms/emails',{
                id: this.editingId,
                html: this.email_html,
                html2: this.email_html2,
                title: this.title,
                subject: this.subject,
                text: this.email_text,
                button_type: this.buttonConstructor.type,
                button_align: this.buttonConstructor.align,
                button_link: this.buttonConstructor.url,
                button_label: this.buttonConstructor.label,
                banner_media_id: this.banner_id
            }).then(response => {
                this.editingId = response.data.id;
                this.$toast.success("Template Saved!");

                if(exit_on_saved) {
                    this.$emit('saveAndClose', this.editingId);
                }
            }).catch(err => {
                this.$toast.error(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]);
            }).finally(r => {
                setTimeout(function(){
                    this.loading = false;
                }.bind(this),3000)
            });
        },
        getClients: function() {
            axios.get('/comms/emails/setup/clients')
                .then(response => {
                    this.clients = response.data;
                    if(!this.client) {
                        this.getClientBranding(this.clients[0].id);
                    }
                })
                .catch(err => { console.log(err) });
        },
        updateClient: function(event) {
            if(event.target.value !== this.active_client_id) {
                this.getClientBranding(event.target.value);
            }
        },
        getClientBranding: function(client) {
            axios.get('/comms/emails/setup/' + client)
                .then(response => {
                    this.client = response.data;
                    this.active_client_id = client;
                })
                .catch(err => { console.log(err) });
        },
        updateBanner: function(banner){
            this.banner_id = banner[0];
            this.banner_src = banner[1];
            this.updatePreview();
        },
        copySource: function(){
            navigator.clipboard.writeText(this.compiled_html_preview);
            this.$toast.success("Source Copied!");
        },
        saveAndSendTest: function(args){
            this.saveTemplate(false);
            setTimeout(function() {
                if (this.editingId) {
                    axios.post('/comms/emails/test/' + this.active_client_id, {
                        acq: args[1],
                        customer_id: args[0],
                        template_id: this.editingId,
                        test_recipient: args[2],
                        as_recommendation: args[3]
                    })
                        .then(response => {
                            this.$toast.success("Test Sent!");
                        })
                } else {
                    this.$toast.error("Test Failed, Please Retry!");
                }
            }.bind(this), 2000);
        }
    },
    computed: {
        sender: function()
        {
            return this.client ? this.client.brand.sender : null;
        },
        buttonPreview: function () {
            let button_colour = '#000000';

            if(this.client && this.client.brand && this.client.brand.colours) {
                button_colour = this.buttonConstructor.type === 'primary' ? this.client.brand.colours.primary : this.client.brand.colours.secondary;
            }

            return '<table border="0" width="100%"><tr><td align="' + this.buttonConstructor.align + '"><a style="background: ' + button_colour + '; box-shadow: none !important; border: 1px solid transparent; border-top: 10px solid ' + button_colour + '; border-right: 18px solid ' + button_colour + '; margin-top: 10px; border-bottom: 10px solid ' + button_colour + '; border-left: 18px solid ' + button_colour + '; text-decoration: none; color: #fff; text-align: center; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; display: inline-block;" title="' + this.buttonConstructor.label + '" href="' + this.buttonConstructor.url + '">' + this.buttonConstructor.label + ' &rarr;</a></td></tr></table>';
        }
    },
    watch: {
        email_html: function(){
            if(this.client) {
                this.updatePreview();
            }
        },
        email_html2: function(){
            if(this.client) {
                this.updatePreview();
            }
        },
        client: function(){
            this.updatePreview();
        }
    }
}
</script>

<style scoped>
    /deep/ .ql-container.ql-snow, /deep/ .ql-toolbar.ql-snow {
        border:0px solid #e2e8f0 !important;
        border-bottom: 1px solid #e2e8f0 !important;
    }

    /deep/ .ql-container.ql-snow {
        @apply text-gray-800;
    }

    /deep/ .ql-container.ql-snow p {
        @apply text-sm;
    }

    /deep/ .ql-editor {
        min-height: 360px;
        font-size: 13px;
    }

    /deep/ .ql-editor p {
        margin-top:14px;
    }

    /deep/ .ql-editor p:first-child {
        margin-top:0px;
    }

    /deep/ .ql-editor.ql-blank::before {
        @apply text-gray-500 not-italic;
    }

    /deep/ .media-input label {
        @apply hidden;
    }

    /deep/ .media-input > .media-input-actions {
        @apply border-0 border-b;
    }

    /deep/ .media-preview > .replace-button-container {
        @apply text-center;
    }
</style>
