<template>
    <div id="media-preview" class="media-preview">
        <div class="w-100 clearfix text-center" v-if="previewsrc && showPreview">
            <div class="w-100 bg-gray-200">
                <img alt="Media Preview" v-bind:src="previewsrc" class="mx-auto">
            </div>
        </div>
        <div class="w-100 py-3 border-top text-right bg-gray-300 replace-button-container" style="padding-left: 0.75rem; padding-right: 0.75rem;">
            <button class="btn btn-outline-dark btn-sm btn-rounded" type="button" @click="replaceImage">Replace {{ mediaType }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MediaPreview",
    props: {
        previewsrc: {
            default: null
        },
        mediaType: {
            default: null
        },
        showPreview: {
            default: true
        }
    },
    methods: {
        replaceImage: function () {
            this.$emit('replaceMedia')
        }
    }
}
</script>

<style scoped>
img {
    max-width: 100%;
    height: auto;
}
#media-preview {
    background: #cecece;
}
</style>
