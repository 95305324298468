<template>
    <div id="enrichment-stats">
        <span class="block">Companies Enriched (Today): {{ count }}</span>
        <span class="block">Companies Enriched (All): {{ all }}</span>
    </div>
</template>

<script>
export default {
    name: "CompanyEnrichmentCounter",
    data() {
        return {
            'count': 0,
            'all': 0,
        }
    },
    mounted() {
        this.updateCount();
    },
    methods: {
        updateCount: function () {
            axios.get('/companies/enriched/count').then(response => {
                this.count = response.data.total;
                this.all = response.data.all;
            }).then(response => {
                setTimeout(this.updateCount, 60000); // Refresh every Minute
            });
        },
    }
}
</script>

<style scoped>

</style>
