<template>
    <div>
        <label for="title" class="inline-block w-full">Trigger Title</label>
        <input id="title" type="text" name="title" v-model="title">

        <label for="description" class="inline-block w-full mt-3">Description</label>
        <textarea name="description" id="description" v-model="description"></textarea>

        <label for="type" class="inline-block w-full mt-3">Trigger Type</label>
        <select id="type" name="type" v-model="type" required>
            <option value="realtime">Realtime</option>
            <option value="reminder">Reminder</option>
        </select>

        <p class="text-xs mt-3"><strong>Realtime:</strong> Evaluates Company updates as they happen.<br> <strong>Reminder:</strong> Triggers a reminder X days before a specific date in a Company's calendar.</p>

        <trigger-conditions-realtime class="mt-8 pt-4 border-t" v-if="type === 'realtime'" v-bind:trigger="trigger" v-bind:options="options"></trigger-conditions-realtime>
        <trigger-conditions-reminder class="mt-8 pt-4 border-t" v-if="type === 'reminder'" v-bind:trigger="trigger" v-bind:options="options"></trigger-conditions-reminder>

        <div class="mt-8 pt-4 border-t mb-8 pb-4 border-b">
            <label for="interval" class="inline-block w-full mt-3">Interval before retrigger (Days)</label>
            <input type="number" step="1" v-model="interval" id="interval" name="interval">
            <p class="text-xs mt-3">Enter 0 for a one-time notification.</p>
        </div>

    </div>
</template>

<script>
export default {
    name: "TriggerForm",
    props: {
        trigger: {
            default: null,
        },
    },
    data: function() {
        return {
            type: "realtime",
            options: [],
            interval: 0,
            title: null,
            description: null,
            loadedTrigger: null
        }
    },
    mounted() {

        if(this.trigger) {
            this.loadedTrigger = this.trigger;
            this.type = this.loadedTrigger.type;
            this.interval = this.loadedTrigger.interval;
            this.title = this.loadedTrigger.title;
            this.description = this.loadedTrigger.description;
        }

        axios.post('/comms/triggers/types').then(response => {
            this.options = response.data;
        });
    }
}
</script>

<style scoped>

</style>
