<template>
    <div id="company-profile">
        <div v-if="loaded" class="grid">
            <tabs>
                <tab title="Company Information" v-if="company.registered_company">
                    <registered-company class="my-2" v-bind:company="company.registered_company"></registered-company>
                </tab>
                <tab title="Enriched Profile" v-if="company.enriched_company_profile">
                    <enriched-company-profile class="my-2" v-bind:company="company.enriched_company_profile"></enriched-company-profile>
                </tab>
                <tab title="Financials" v-if="company.enriched_company_financials">
                    <div class="bg-white h-20 overflow-hidden flex w-full content-center items-center border-b border-gray-200">
                        <div class="mr-auto px-4 py-5 sm:px-6 items-center">
                            <p class="mb-0 max-w-2xl text-sm leading-none text-gray-500">
                                <strong>Source:</strong> DueDil API
                            </p>
                        </div>
                        <div class="hidden ml-auto xl:flex items-center leading-none">
                            <label class="m-0">Select Year:</label>
                        </div>
                        <div class="ml-auto xl:ml-1 flex items-center pr-4 py-5 sm:pr-6">
                        <div class="flex-grow-0 ml-2 relative w-32">
                            <select class="text-xs" v-model="activeReport">
                                <option v-for="(report,index) in sortedFinancials" :value="index">{{ new Date(report.accountsDate).getFullYear() }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        </div>
                    </div>
                    <enriched-company-financials v-bind:company="financialReport"></enriched-company-financials>
                </tab>
                <tab title="Officers" v-if="company.enriched_company_officers">
                    <enriched-company-officers class="my-2" v-bind:company="company.enriched_company_officers"></enriched-company-officers>
                </tab>
                <tab title="Comms Calendar" v-if="company.calendar_dates">
                    <company-comms-calendar v-bind:dates="company.calendar_dates"></company-comms-calendar>
                </tab>
            </tabs>
        </div>
    </div>
</template>

<script>
import CompanyCommsCalendar from "./CompanyCommsCalendar";
export default {
    name: "CompanyProfile",
    components: {CompanyCommsCalendar},
    props: ['companyid'],
    data: function(){
        return {
            'loaded': false,
            'company': null,
            'activeReport': null
        }
    },
    mounted() {
        this.loadResource();
    },
    methods: {
        loadResource: function () {
            axios.get('/companies/resource/' + this.companyid).then(response => {
                this.company = response.data.data;
            }).then(response => {
                this.loaded = true;
                this.activeReport = 0;
            });
        }
    },
    computed: {
        sortedFinancials: function(){
            if(this.company.enriched_company_financials){
                return this.company.enriched_company_financials.sort((a, b) => new Date(b.accountsDate) - new Date(a.accountsDate))
            }
        },
        financialReport: function(){
            if(this.company.enriched_company_financials && !isNaN(this.activeReport)){
                return this.sortedFinancials[this.activeReport];
            }
        }
    }
}
</script>
