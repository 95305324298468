<template>
    <span @click.prevent="deleteTemplate" class="cursor-pointer">Delete</span>
</template>

<script>
export default {
    name: "EmailDeleteButton",
    props: {
        email_id: {
            default: null
        }
    },
    methods: {
        deleteTemplate: function() {
            if(confirm("Do you really want to delete?")){
                axios.delete('/comms/emails/' + this.email_id)
                    .then(resp => {
                        location.replace('/comms/emails');
                    })
                    .catch(err => {
                        this.$toast.error(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]);
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
