<template>
    <div v-show="isActive" class="w-full h-screen fixed block top-0 left-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center">
        <div class="modal-container border-gray-400 rounded-lg border px-3 py-4 my-2 bg-white w-11/12 md:max-w-xl mx-auto" :class="containerClass">
            <div class="text-2xl font-bold text-secondary leading-none normal-case mb-4 mt-3 px-3">
                {{ title }}
            </div>
            <slot :closeModal="closeModal"></slot>
        </div>
    </div>
</template>

<script>
export default {
name: "Modal",
    props: {
        title: {
            type: String,
            default: 'Modal Title'
        },
        containerClass: ""
    },
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        closeModal: function(){
            this.isActive = false;
        },
        openModal: function(){
            this.isActive = true;
        }
    }
}
</script>

<style scoped>

</style>
