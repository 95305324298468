<template>
    <div class="bg-white overflow-hidden">
        <div class="px-4 py-5 border-b border-gray-200 sm:px-6 h-20 items-center flex">
            <p class="mb-0 max-w-2xl text-sm leading-5 text-gray-500">
                <strong>Source:</strong> Companies House API
            </p>
        </div>
        <div class="bg-gray-50 py-1 px-6">
                <table class="table-fixed min-w-full my-5">
                    <thead>
                    <tr>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">Role</th>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">Name</th>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">Appointed On</th>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">Resigned On</th>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">Occupation</th>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">DOB</th>
                        <th class="py-3 bg-gray-50 text-left leading-4 uppercase tracking-wide text-gray-700 text-xs font-bold">Postcode</th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                    <tr v-for="(value, key) in officers" class="text-xs">
                        <td>{{ value.officer_role.charAt(0).toUpperCase() + value.officer_role.slice(1)
                            }}</td>
                        <td>{{ value.name }}</td>
                        <td>{{ value.appointed_on }}</td>
                        <td>{{ value.resigned_on }}</td>
                        <td>{{ value.occupation }}</td>
                        <td v-if="value.date_of_birth">{{ dob(value.date_of_birth) }}</td>
                        <td v-else></td>
                        <td>{{ value.address.postal_code }}</td>
                    </tr>
                    </tbody>
                </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "EnrichedCompanyOfficers",
    props: ['company'],
    data: function () {
        return {}
    },
    methods: {
      dob: function(dob){
          let dobtext = "";
          if(dob.day){
              dobtext += dob.day.toString().padStart(2,'0') + ".";
          }
          if(dob.month){
              dobtext += dob.month.toString().padStart(2,'0') + ".";
          }
          if(dob.year){
              dobtext += dob.year;
          }
          return dobtext;
      }
    },
    computed: {
        officers: function () {
            let newArray = [];
            this.company.forEach(function(o){
                newArray.push(o);
            });
            return newArray;
        }
    }
}
</script>
